@import '~./style//color.less';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
  color: #212121;
  user-select: none;
}
ul, li{
  list-style: none;
  margin: 0;
  padding: 0;
}
a{
  color: @main;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.tl{
  text-align: left;
}
.tc{
  text-align: center;
}
.tr{
  text-align: right;
}
.header-fix{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 990;
}
.header-fix-content{
  padding-top:45px;
}
.header-tab-fix-content{
  padding-top:90px;
}
.tab-fix-content{
  padding-bottom: 80px;
}
.bt1, .bb1{
  position: relative;
  &:after{
    content:'';
    position:absolute;
    border:solid #DDD;
    width:100%;
    height:1px;
    left:0;
    border-width: 0;
    -webkit-transform:scaleY(.5);transform:scaleY(.5);
  }
}
.bt1:after{
  top:0;
  border-top-width: 1px;
}
.bb1:after{
  bottom: 0;
  border-bottom-width: 1px;
}
.bl1, .br1{
  position: relative;
  &:after{
    content:'';
    position:absolute;
    border:solid #DDD;
    height: 100%;
    width:1px;
    top:0;
    border-width: 0;
    -webkit-transform:scaleX(.5);transform:scaleX(.5);
  }
}
.bl1:after{
  left:0;
  border-left-width: 1px;
}
.br1:after{
  right: 0;
  border-right-width: 1px;
}

.border { border: 1px solid #eee }
.border.input {border-width: 0 0 1px 0;}
@media screen and (-webkit-min-device-pixel-ratio: 2) {
    .border { border: 0.5px solid #eee }
}
@media screen and (-webkit-min-device-pixel-ratio: 3) {
    .border { border: 0.333333px solid #eee }
}
.am-list-body:before, .am-list-body:after{
  background-color: @bodybg !important;
}
.am-modal-button{
  height: 40px !important;
  line-height: 40px !important;
  font-size: 14px !important;
}

.step-list-item{
  color:#333;
  line-height: 22px;
  margin-bottom: 20px;
  .sort{
      font-size: 20px;
      font-weight: bold;
      color: @main;
      margin-right: 4px;
  }
  span{
      color: @main;
      font-weight: bold;
  }
  .imgview{
      img{width: 100%;}
  }
}
.border-bottom{
  position: relative;
  &::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: @border;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
  }
  &.border-white{
    &::after{
      background-color: #FFF;
    }
  }
}
.am-list-item .am-input-control input{
  font-size: 16px;
}
.full-modal{
  width: 100%;
  .am-modal-body{padding: 0 !important;}
  .am-modal-content{
      background-color: transparent;
      padding: 0;
  }
}
.modal-bottom{
  background-color: #FFF;
  padding:8px 0;
  color: #666;
  font-size: 14px;
  .iconfont{
    font-size: 20px;
    display: inline-block;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    color: #FFF;
    border-radius: 25px;
    margin-bottom: 4px;
    &.green{
      background-color: #2ADF81;
    }
    &.yellow{
      background-color: #FEB941;
    }
  }
}
.flex1{
  flex:1;
}
.flex2{
  flex:2;
}
.flex3{
  flex:3;
}
.flex4{
  flex:4;
}
.flex5{
  flex:5;
}
.flex6{
  flex:6;
}

.pop-rule {
  background: #fff;
  border-radius: 16px;
  width: 16rem;
  padding: 0.8rem;
}
.pop-mid {
  position: fixed;
  z-index: 960;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  box-sizing: border-box;
}
.pop-rule-hd {
  text-align: center;
  font-size: .9rem;
  font-weight: 700;
}
.pop-rule-bd {
  max-height: 15rem;
  margin-top: 0.75rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  h4{
    margin: 0 0 .75rem;
  }
  p {
    font-size: .65rem;
    color: #494949;
    line-height: .95rem;
    &.red{
      color: #FF2B49;
    }
  }
}
.pop-rule .icon-closed {
  position: absolute;
  top: auto;
  left: 50%;
  bottom: -3.2rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 1.9rem;
  height: 1.9rem;
  background: url('./images/icon-closed.png') no-repeat;
  background-size: cover;
}
.t-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.t-mask {
  z-index: 500;
  background: rgba(0,0,0,.6);
}
.paddingHeader {
  padding-top: 2.2rem;
}
.pop-bottom {
  position: fixed;
  transform: translate3d(0,0,0);
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 960;
}
@brand-primary: #f9191c;@brand-primary-tap: #EF233C;@fill-body: #F9F9F9;@color-text-base: #212121;